import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import customersSectionStyle from "assets/jss/finwe/customersSectionStyle.jsx";

import Aftonbladet from "assets/img/logos/aftonbladet-480x480.png";
import AgnicoEagle from "assets/img/logos/agnicoeagle-480x480.png";
import AIG from "assets/img/logos/aig-480x480.png";
import Arrow from "assets/img/logos/arrow-480x480.png";
import ATT from "assets/img/logos/att-480x480.png";
import Ateme from "assets/img/logos/ateme-480x480.png";
import BReel from "assets/img/logos/breel-480x480.png";
import BBCRnD from "assets/img/logos/bbc_r_d-480x480.png";
import Bittium from "assets/img/logos/bittium-480x480.png";
import CSC from "assets/img/logos/csc-480x480.png";
import Destia from "assets/img/logos/destia-480x480.png";
import DiscoveryVR from "assets/img/logos/discoveryvr-480x480.png";
import EF from "assets/img/logos/ef-480x480.png";
import Elisa from "assets/img/logos/elisa-480x480.png";
import EPEC from "assets/img/logos/epec-480x480.png";
import Erillisverkot from "assets/img/logos/erillisverkot-480x480.png";
import ESA from "assets/img/logos/esa-480x480.png";
import Etisalat from "assets/img/logos/etisalat-480x480.png";
import Eurecom from "assets/img/logos/eurecom-480x480.png";
import Fincloud from "assets/img/logos/fincloud-480x480.png";
import FinnLamex from "assets/img/logos/finnlamex-480x480.png";
import Fortum from "assets/img/logos/fortum-480x480.png";
import GallifordTry from "assets/img/logos/gallifordtry-480x480.png";
import GoPro from "assets/img/logos/gopro-480x480.png";
import GSK from "assets/img/logos/gsk-480x480.png";
import Halton from "assets/img/logos/halton-480x480.png";
//import Hammerhead from "assets/img/logos/hammerhead-480x480.png";
import Harman from "assets/img/logos/harman-480x480.png";
import InescTec from "assets/img/logos/inesctec-480x480.png";
import Intel from "assets/img/logos/intel-480x480.png";
import Inria from "assets/img/logos/inria-480x480.png";
import Kolor from "assets/img/logos/kolor-480x480.png";
import KonceptVR from "assets/img/logos/konceptvr-480x480.png";
import KoneCranes from "assets/img/logos/konecranes-480x480.png";
import Lexus from "assets/img/logos/lexus-480x480.png";
import LibertyMutual from "assets/img/logos/libertymutual-480x480.png";
import Liiga from "assets/img/logos/liiga-480x480.png";
//import Littlstar from "assets/img/logos/littlstar-480x480.png";
import Mammut from "assets/img/logos/mammut-480x480.png";
import McDonalds from "assets/img/logos/mcdonalds-480x480.png";
import MediaTek from "assets/img/logos/mediatek-480x480.png";
import MTVMobile from "assets/img/logos/mtvmobile-480x480.png";
import Nelonen from "assets/img/logos/nelonen-480x480.png";
import NEOT from "assets/img/logos/neot-480x480.png";
import NLS from "assets/img/logos/nls-480x480.png";
import NordicBusinessForum from "assets/img/logos/nbf-480x480.png";
import Nokia from "assets/img/logos/nokia-480x480.png";
import OKI from "assets/img/logos/oki-480x480.png";
import Oulu from "assets/img/logos/oulu-480x480.png";
import RadiantImages from "assets/img/logos/radiantimages-480x480.png";
import Rohm from "assets/img/logos/rohm-480x480.png";
import Polar from "assets/img/logos/polar-480x480.png";
import Rightware from "assets/img/logos/rightware-480x480.png";
import Pelastusopisto from "assets/img/logos/pelastusopisto-480x480.png";
import Ponsse from "assets/img/logos/ponsse-480x480.png";
import PortOulu from "assets/img/logos/portoulu-480x480.png";
import SAS from "assets/img/logos/sas-480x480.png";
import SBS from "assets/img/logos/sbs-480x480.png";
import SonyMusic from "assets/img/logos/sonymusic-480x480.png";
import StoraEnso from "assets/img/logos/storaenso-480x480.png";
import Sweco from "assets/img/logos/sweco-480x480.png";
import InstitutoAyrtonSenna from "assets/img/logos/senna-480x480.png";
import Subaru from "assets/img/logos/subaru-480x480.png";
import Telia from "assets/img/logos/telia-480x480.png";
import TexasInstruments from "assets/img/logos/texas_inst-480x480.png";
import ULA from "assets/img/logos/ula-480x480.png";
import UniversalMusic from "assets/img/logos/universalmusic-480x480.png";
import Vantaa from "assets/img/logos/vantaa-480x480.png";
import VTT from "assets/img/logos/vtt-480x480.png";
import Warner from "assets/img/logos/warner-480x480.png";
import WorldVision from "assets/img/logos/worldvision-480x480.png";
import YLE from "assets/img/logos/yle-480x480.png";
import { Grid } from '@material-ui/core';

class CustomersSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">
            <Card>
              <CardBody>
                <GridContainer>

                  {/* Telecommunications */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Nokia}
                      alt="Nokia"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Telia}
                      alt="Telia"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Intel}
                      alt="Intel"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={ATT}
                      alt="ATT"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Elisa}
                      alt="Elisa"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Erillisverkot}
                      alt="Erillisverkot"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Fincloud}
                      alt="FinCloud.tv"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Etisalat}
                      alt="Etisalat"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={MediaTek}
                      alt="MediaTek"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Energy */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Fortum}
                      alt="Fortum"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={NEOT}
                      alt="NEOT"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Agriculture, Forestry, Pulp & Paper, Mining */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Ponsse}
                      alt="Ponsse"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={StoraEnso}
                      alt="StoraEnso"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={AgnicoEagle}
                      alt="Agnico Eagle"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Software & Electronics */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={TexasInstruments}
                      alt="Texas Instruments"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={GoPro}
                      alt="GoPro"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Harman}
                      alt="Harman"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Ateme}
                      alt="Ateme"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={SAS}
                      alt="SAS"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Rohm}
                      alt="Rohm"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={OKI}
                      alt="OKI"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Arrow}
                      alt="Arrow"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Bittium}
                      alt="Bittium"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={EPEC}
                      alt="EPEC"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Polar}
                      alt="Polar"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={VTT}
                      alt="VTT"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={NLS}
                      alt="NLS"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={CSC}
                      alt="CSC"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={InescTec}
                      alt="InescTec"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Inria}
                      alt="Inria"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Eurecom}
                      alt="Eurecom"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Vehicles & Transport */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={ESA}
                      alt="ESA"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={ULA}
                      alt="ULA"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Lexus}
                      alt="Lexus"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Subaru}
                      alt="Subaru"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={PortOulu}
                      alt="PortOulu"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>                 

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={KoneCranes}
                      alt="KoneCranes"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={FinnLamex}
                      alt="FinnLamex"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Medical */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={GSK}
                      alt="GSK"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Halton}
                      alt="Halton"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Vantaa}
                      alt="Vantaa"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Oulu}
                      alt="Oulu"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Pelastusopisto}
                      alt="Pelastusopisto"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Economy, Finance & Insurance */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={AIG}
                      alt="AIG"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={LibertyMutual}
                      alt="LibertyMutual"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Construction */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={GallifordTry}
                      alt="GallifordTry"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Destia}
                      alt="Destia"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Sweco}
                      alt="Sweco"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Consumer Brands */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Mammut}
                      alt="Mammut"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={McDonalds}
                      alt="McDonalds"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Music & Media */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={MTVMobile}
                      alt="MTV Mobile"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Warner}
                      alt="Warner Music"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={UniversalMusic}
                      alt="Universal Music"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={SonyMusic}
                      alt="Sony Music"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={SBS}
                      alt="SBS"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={BBCRnD}
                      alt="BBC RnD"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={DiscoveryVR}
                      alt="Discovery VR"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Liiga}
                      alt="Liiga"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={YLE}
                      alt="YLE"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Nelonen}
                      alt="Nelonen"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Aftonbladet}
                      alt="Aftonbladet"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Organizations */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={WorldVision}
                      alt="World Vision"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={NordicBusinessForum}
                      alt="Nordic Business Forum"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={EF}
                      alt="EF"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={InstitutoAyrtonSenna}
                      alt="Instituto Ayrton Senna"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/* Agencies */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={KonceptVR}
                      alt="KonceptVR"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={RadiantImages}
                      alt="Radiant Images"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Kolor}
                      alt="Kolor"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={BReel}
                      alt="B-Reel"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                  {/*
                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Littlstar}
                      alt="Littlstar"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  */}

                  {/*
                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Hammerhead}
                      alt="Hammerhead"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  */}

                  <GridItem xs={4} sm={3} md={2} lg={2}>
                    <img
                      src={Rightware}
                      alt="Rightware"
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>

                </GridContainer>

              </CardBody>
            </Card>

          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(customersSectionStyle)(CustomersSection);
